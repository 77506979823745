/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */

require('./bootstrap');

window.$(function () {
    function cb(start, end)
    {
        var range = start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY');

        window.$(this).val(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
        window.livewire.emit('set:range',range);
    }

    window.$('.report-range > input').daterangepicker({
        autoUpdateInput: false,
        locale: {
            cancelLabel: 'Clear'
        },
        ranges: {
            'Today': [window.moment(), window.moment()],
            'Yesterday': [window.moment().subtract(1, 'days'), window.moment().subtract(1, 'days')],
            'Last 7 Days': [window.moment().subtract(6, 'days'), window.moment()],
            'Last 30 Days': [window.moment().subtract(29, 'days'), window.moment()],
            'This Month': [window.moment().startOf('month'), window.moment().endOf('month')],
            'Last Month': [window.moment().subtract(1, 'month').startOf('month'), window.moment().subtract(1, 'month').endOf('month')]
        }
    }, cb).on('apply.daterangepicker', function (ev, picker) {
        var range = picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY');
        window.$(this).val(range);
        window.livewire.emit('set:range',range);
    }).on('cancel.daterangepicker', function () {
        window.$(this).val('');
        window.livewire.emit('set:range','');
    });

    setInterval(function () {
        $('.alert').delay(5000).slideUp(200, function () {
            $(this).alert('close');
        });
    }, 1000);
});
